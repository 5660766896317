<template>
  <v-dialog v-model="dialog" width="600">
    <template #activator="{ on, attrs }">
      <v-btn
        color="ap-orange"
        dark
        fab
        fixed
        bottom
        right
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>$mdi-code-tags</v-icon>
      </v-btn>
    </template>

    <DevFeaturesCard
      @feature-selected="onFeatureSelected"
      @close="dialog = false"
    />
  </v-dialog>
</template>

<script>
import DevFeaturesCard from './DevFeaturesCard'

export default {
  components: {
    DevFeaturesCard,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    onFeatureSelected(feature) {
      if (feature.to) {
        this.dialog = false
      }
    },
  },
}
</script>
